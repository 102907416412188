import { ArrowLeft } from "../../assets/icons";
import { NavLink } from "react-router-dom";

const NavBarMission = ({ user, isWaiting }) => {
  return (
    <nav className={"w-full flex justify-between items-center"}>
      <div className={"flex gap-4 items-center"}>
        <NavLink to={"/hourly-tracking"} className={"p-3 border rounded-full"}>
          <ArrowLeft wh={24} color={"#374151"} />
        </NavLink>
        <div className={"flex flex-col"}>
          <div className={"text-2xl font-bold capitalize"}>
            {isWaiting ? (
              <div className={"h-6 bg-gray-200 rounded w-40"}></div>
            ) : (
              `${user?.firstname} ${user?.lastname}`
            )}
          </div>
          <div className={"text-gray-500 text-sm"}>
            {isWaiting ? (
              <div className={"h-4 bg-gray-200 rounded w-20 mt-2"}></div>
            ) : (
              `Matricule : ${user?.peopulseId}`
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBarMission;
