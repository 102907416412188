import { useEffect, useState, useCallback } from "react";
import useTitle from "../hooks/TitleManager";
import PageContainer from "../components/pageContainer/PageContainer";
import ApiDatabase from "../server";
import NavBarActivity from "../components/navbars/NavBarActivity";
import TableActivities from "../components/tables/TableActivities";
import EmptyState from "../components/EmptyState/EmptyState";
import {
  dateLong,
  dateShort,
  dateShortHours,
  dateShortHoursWithoutYear,
} from "../utils/Date";
import Button from "../components/button";
import { EyeIcon } from "../assets/icons";
import useModal from "../components/modal/useModal";
import UseContext from "../hooks/useContext";
import Fuse from "fuse.js";
import removeAccents from "remove-accents";
import Pagination from "../components/pagination/Pagination";

const Activity = () => {
  useTitle({ title: "Activité" });
  const { toggle } = useModal();
  const { setModalData } = UseContext();
  const [activities, setActivities] = useState([]);
  const [activitiesToDisplay, setActivitiesToDisplay] = useState([]);
  const [isWaitingActivity, setIsWaitingActivity] = useState(true);

  const [companySelectedActivity, setCompanySelectedActivity] = useState([]);
  const [employeeSelectedActivity, setEmployeeSelectedActivity] = useState([]);
  const [dateSelect, setDateSelect] = useState(null);
  const [search, setSearch] = useState("");
  const [actionSelected, setActionSelected] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const countPerPage = 25;
  const [countTotal, setCountTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    search,
    companySelectedActivity,
    employeeSelectedActivity,
    dateSelect,
    actionSelected,
  ]);

  useEffect(() => {
    setIsWaitingActivity(true);
    ApiDatabase.getActivities(
      {},
      (data) => {
        setActivities(data);
        setIsWaitingActivity(false);
      },
      (err) => {
        setIsWaitingActivity(false);
        console.log(err);
      },
    );
  }, []);

  const openModalActivity = useCallback(
    (activity) => {
      toggle("log_time_sheet");
      setModalData({
        employee: activity.employee,
        mission: activity.description?.title?.text,
        date: dateShortHoursWithoutYear(activity.date, "/"),
        details: activity.details,
      });
    },
    [toggle, setModalData],
  );

  const transformActivities = useCallback((activities) => {
    return activities
      .map((activity) => {
        if (activity.type === "exportMission") {
          return {
            type: activity.type,
            typeStatus: activity.typeStatus,
            idEmployee: activity.idEmployee,
            idCompany: activity.idCompany,
            date: (
              <div className={"text-sm text-gray-500"}>
                {dateShortHours(activity.date, "/")}
              </div>
            ),
            dateOriginal: activity.date,
            employee: <div className={"text-sm"}>{activity.employee}</div>,
            title: <div className={"text-sm"}>{activity.title?.text}</div>,
            description: (
              <div className={"text-sm"}>
                <div>{activity.description?.title?.text}</div>
                <div className={"text-gray-500 flex gap-1"}>
                  {dateShort(activity.description?.description?.date?.start)} >{" "}
                  {dateShort(activity.description?.description?.date?.end)}
                </div>
              </div>
            ),
            search: `${activity.description?.title?.text} ${activity.description?.description?.date?.start} ${activity.description?.description?.date?.end}`,
          };
        } else if (activity.type === "tempWorkerPhone") {
          return {
            type: activity.type,
            typeStatus: activity.typeStatus,
            idEmployee: activity.idEmployee,
            idCompany: activity.idCompany,
            date: (
              <div className={"text-sm text-gray-500"}>
                {dateShortHours(activity.date, "/")}
              </div>
            ),
            dateOriginal: activity.date,
            employee: <div className={"text-sm"}>{activity.employee}</div>,
            title: <div className={"text-sm"}>{activity.title.text}</div>,
            description: (
              <div className={"text-sm"}>
                <div>{activity.description?.title?.text}</div>
                <div className={"text-gray-500"}>
                  {activity.description?.description?.text}
                </div>
              </div>
            ),
            search: `${activity.description?.title?.text} ${activity.description?.description?.text}`,
          };
        } else if (activity.type === "timeSheet") {
          return {
            type: activity.type,
            typeStatus: activity.typeStatus,
            idEmployee: activity.idEmployee,
            idCompany: activity.idCompany,
            date: (
              <div className={"text-sm text-gray-500"}>
                {dateShortHours(activity.date, "/")}
              </div>
            ),
            dateOriginal: activity.date,
            employee: <div className={"text-sm"}>{activity.employee}</div>,
            title: (
              <div className={"flex gap-1 justify-between items-center"}>
                <div className={"text-sm flex gap-1"}>
                  <div>{activity.title.text}</div>
                  <div className={"capitalize"}>
                    ({dateLong(new Date(activity.title.date))})
                  </div>
                </div>
                <Button
                  size={"ICON"}
                  color={"ICON"}
                  onClick={() => openModalActivity(activity)}
                >
                  <EyeIcon wh={20} color={"#374151"} />
                </Button>
              </div>
            ),
            description: (
              <div className={"text-sm"}>
                <div>{activity.description?.title?.text}</div>
              </div>
            ),
            search: `${activity.description?.title?.text}`,
          };
        }
        return null;
      })
      .filter((activity) => activity !== null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activities.length > 0) {
      const activitiesToDisplay = transformActivities(activities);
      let sortedResults = activitiesToDisplay;

      if (removeAccents(search.toLowerCase()) !== "") {
        const searchTerms = search
          .split(" ")
          .map((term) => removeAccents(term.toLowerCase()));

        const options = {
          keys: ["search"],
          includeScore: true,
          threshold: 0.3,
          distance: 100,
          shouldSort: true,
          getFn: (obj, path) => {
            const value = Fuse.config.getFn(obj, path);
            if (value == null) {
              return "";
            }
            return removeAccents(value.toString().toLowerCase());
          },
        };

        const fuse = new Fuse(activitiesToDisplay, options);
        const combinedResults = {};
        searchTerms.forEach((term) => {
          const result = fuse.search(term);
          result.forEach(({ item, score }) => {
            const indexSearch = `${item.dateOriginal}${item.idEmployee}${item.typeStatus}${item.search}`;
            if (combinedResults[indexSearch]) {
              combinedResults[indexSearch].score -= score;
            } else {
              combinedResults[indexSearch] = { item, score };
            }
          });
        });

        sortedResults = Object.values(combinedResults)
          .sort((a, b) => a.score - b.score)
          .map((result) => result.item);
      }

      const activitiesResult = sortedResults.filter((activity) => {
        let isCompany = true;
        let isEmployee = true;
        let isDate = true;
        let isAction = true;

        if (companySelectedActivity.length > 0) {
          isCompany = companySelectedActivity.some(
            (employee) => employee.idCompany === activity.idCompany,
          );
        }
        if (employeeSelectedActivity.length > 0) {
          isEmployee = employeeSelectedActivity.some(
            (employee) => employee.idEmployee === activity.idEmployee,
          );
        }
        if (dateSelect) {
          isDate =
            dateSelect.toDateString() ===
            new Date(activity.dateOriginal).toDateString();
        }
        if (actionSelected.length > 0) {
          isAction = actionSelected.some(
            (action) => action.type === activity.typeStatus,
          );
        }

        return isCompany && isEmployee && isDate && isAction;
      });

      setCountTotal(activitiesResult.length);
      setTotalPages(Math.ceil(activitiesResult.length / countPerPage));
      const start = (currentPage - 1) * countPerPage;
      const end = start + countPerPage;

      setActivitiesToDisplay(activitiesResult.slice(start, end));
    } else {
      setActivitiesToDisplay([]);
    }
  }, [
    activities,
    transformActivities,
    companySelectedActivity,
    actionSelected,
    employeeSelectedActivity,
    dateSelect,
    search,
    currentPage,
  ]);

  return (
    <PageContainer type={"activity"}>
      <div className={"flex flex-1 flex-col gap-4 h-full"}>
        <NavBarActivity
          companySelectedActivity={companySelectedActivity}
          setCompanySelectedActivity={setCompanySelectedActivity}
          employeeSelectedActivity={employeeSelectedActivity}
          setEmployeeSelectedActivity={setEmployeeSelectedActivity}
          dateSelect={dateSelect}
          setDateSelect={setDateSelect}
          search={search}
          setSearch={setSearch}
          actionSelected={actionSelected}
          setActionSelected={setActionSelected}
        />
        <div className={"h-0.5 w-full bg-gray-100"} />
        <div className={"flex flex-1 flex-col h-full overflow-auto"}>
          {isWaitingActivity ? (
            <div className={"flex flex-col gap-3 min-w-[830px] animate-pulse"}>
              {[1, 2, 3, 4, 5].map((index) => (
                <TableActivities key={index} isWaiting={true} />
              ))}
            </div>
          ) : (
            <>
              {activitiesToDisplay.length > 0 ? (
                <div className={"flex flex-col gap-3 min-w-[830px]"}>
                  <div className={`grid grid-cols-5 gap-8 px-4 py-3 text-sm`}>
                    <div className={"flex flex-col justify-center"}>
                      Date d'activité
                    </div>
                    <div className={"flex flex-col justify-center"}>
                      Utilisateur
                    </div>
                    <div className={"flex flex-col justify-center col-span-2"}>
                      Action
                    </div>
                    <div className={"flex flex-col justify-center"}>Objet</div>
                  </div>
                  <div className={"h-0.5 w-full bg-gray-100"} />
                  {activitiesToDisplay.map((activity, index) => (
                    <TableActivities key={index} activity={activity} />
                  ))}
                </div>
              ) : (
                <EmptyState type={"activity"} />
              )}
            </>
          )}
        </div>
        <div className={""}>
          <Pagination
            currentPage={currentPage}
            countPerPage={countPerPage}
            countTotal={countTotal}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </PageContainer>
  );
};

export default Activity;
