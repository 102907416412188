import PageContainer from "../components/pageContainer/PageContainer";
import NavBarMission from "../components/navbars/NavBarMission";
import { useEffect, useState } from "react";
import ApiDatabase from "../server";
import { useParams } from "react-router-dom";
import MissionTimeSheet from "../components/mission/MissionTimeSheet";
import {
  InformationCircle,
  Pencil,
  Synchronisation,
  WarningCircle,
} from "../assets/icons";
import Button from "../components/button";
import UseContext from "../hooks/useContext";
import useTitle from "../hooks/TitleManager";
import useModal from "../components/modal/useModal";
import { dateShortText } from "../utils/Date";
import Tooltip from "../components/tooltips/Tooltips";

const Mission = () => {
  useTitle({ title: "Mission" });
  const { toggle } = useModal();
  const {
    slideOverDataReturn,
    setSlideOverDataReturn,
    setModalData,
    modalDataReturn,
    setModalDataReturn,
    timeCut,
    setTimeCut,
    mission,
    setMission,
    userMission,
    setUserMission,
  } = UseContext();
  const [isWaitingMission, setIsWaitingMission] = useState(true);
  const [isWaitingUserMission, setIsWaitingUserMission] = useState(true);
  const [missionDetail, setMissionDetail] = useState({});
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setIsWaitingMission(true);
      ApiDatabase.getMissionById(
        { id },
        (data) => {
          setMissionDetail(data.missionDetail);
          setMission(data.mission);
          setTimeCut(data.timeCut);
          setIsWaitingMission(false);
        },
        (err) => {
          console.log(err);
          setIsWaitingMission(false);
        },
      );
    }
  }, [id, setMission, setTimeCut]);

  useEffect(() => {
    if (id) {
      setIsWaitingUserMission(true);
      ApiDatabase.getUserMissionById(
        { id },
        (data) => {
          setUserMission(data.user);
          setIsWaitingUserMission(false);
        },
        (err) => {
          console.log(err);
          setIsWaitingUserMission(false);
        },
      );
    }
  }, [id, setUserMission]);

  useEffect(() => {
    if (slideOverDataReturn) {
      if (
        slideOverDataReturn.idMission &&
        slideOverDataReturn.date &&
        slideOverDataReturn.timeSheet?.length > 0
      ) {
        const missionIndex = mission.findIndex(
          (m) => m.date === slideOverDataReturn.date,
        );
        if (slideOverDataReturn.idMission === id && missionIndex > -1) {
          const newMission = [...mission];
          if (slideOverDataReturn.idTimeSheet)
            newMission[missionIndex].idTimeSheet =
              slideOverDataReturn.idTimeSheet;
          newMission[missionIndex].markups = slideOverDataReturn.timeSheet;
          newMission[missionIndex].pause = slideOverDataReturn.pause;
          newMission[missionIndex].totalHours = slideOverDataReturn.totalHours;
          setMission(newMission);
        }

        setSlideOverDataReturn({});
      }
    }
  }, [slideOverDataReturn, setSlideOverDataReturn, mission, id, setMission]);

  const editTempWorkerPhone = () => {
    toggle("edit_temp_worker_phone");
    setModalData({
      idTempWorker: userMission.idTempWorker,
      phone: userMission.phone,
    });
  };

  const editTempWorkerToken = () => {
    toggle("edit_temp_worker_token");
    setModalData({
      idTempWorker: userMission.idTempWorker,
    });
  };

  useEffect(() => {
    if (modalDataReturn?.phone) {
      let userMissionChanges = { phone: modalDataReturn.phone };
      if (modalDataReturn.tokenMission) {
        userMissionChanges["tokenMission"] = modalDataReturn.tokenMission;
      }
      setUserMission({
        ...userMission,
        ...userMissionChanges,
      });
      setModalDataReturn({});
    }
    if (modalDataReturn?.tokenMission) {
      setUserMission({
        ...userMission,
        tokenMission: modalDataReturn.tokenMission,
      });
      setModalDataReturn({});
    }
  }, [modalDataReturn, userMission, setModalDataReturn, setUserMission]);

  useEffect(() => {
    console.log("mission", mission);
  }, [mission]);

  return (
    <PageContainer type={"mission"}>
      <div className={"flex flex-1 flex-col gap-6"}>
        <NavBarMission user={userMission} isWaiting={isWaitingUserMission} />
        <div className={"flex flex-1 gap-12"}>
          <div className={"w-full max-w-[654px] flex flex-col gap-8"}>
            {isWaitingUserMission ? (
              <div className={""}></div>
            ) : (
              <div
                className={
                  "flex gap-4 px-4 py-2 border border-gray-100 rounded-lg bg-white"
                }
              >
                <div className={"flex flex-col w-full"}>
                  <div className={"flex gap-1 items-center font-bold"}>
                    <div>Mission</div>
                    <WarningCircle wh={20} color={"#374151"} />
                  </div>
                  <div className={"flex"}>{id}</div>
                </div>
                <div className={"h-full w-0.5 bg-gray-100"}></div>
                <div
                  className={"flex justify-between gap-1 w-full items-center"}
                >
                  <div className={"flex flex-col"}>
                    <div className={"flex gap-1 items-center font-bold"}>
                      <div>Code pin</div>
                      <WarningCircle wh={20} color={"#374151"} />
                    </div>
                    <div className={"flex"}>
                      {userMission.tokenMission
                        ? userMission.tokenMission
                        : "Non défini"}
                    </div>
                  </div>
                  <Button
                    color={"ICON"}
                    size={"ICON"}
                    onClick={editTempWorkerToken}
                  >
                    <Synchronisation wh={20} color={"#374151"} />
                  </Button>
                </div>
                <div className={"h-full w-0.5 bg-gray-100"}></div>
                <div
                  className={"flex justify-between gap-1 w-full items-center"}
                >
                  <div className={"flex flex-col"}>
                    <div className={"flex gap-1 items-center font-bold"}>
                      <div>Téléphone</div>
                      <WarningCircle wh={20} color={"#374151"} />
                    </div>
                    <div className={"flex"}>
                      {userMission.phone ? userMission.phone : "Non défini"}
                    </div>
                  </div>
                  <Button
                    color={"ICON"}
                    size={"ICON"}
                    onClick={editTempWorkerPhone}
                  >
                    <Pencil wh={20} color={"#374151"} />
                  </Button>
                </div>
              </div>
            )}
            {isWaitingMission ? (
              <>
                {[1, 2, 3, 4, 5].map((i, index) => (
                  <MissionTimeSheet key={index} isWaiting={isWaitingMission} />
                ))}
              </>
            ) : (
              <>
                {mission?.length ? (
                  <>
                    {mission.map((item, index) => (
                      <MissionTimeSheet
                        key={index}
                        mission={item}
                        timeCut={timeCut}
                      />
                    ))}
                  </>
                ) : (
                  <div
                    className={
                      "flex flex-col gap-4 items-center justify-center"
                    }
                  >
                    <h1 className={"text-2xl font-bold"}>
                      Mission introuvable
                    </h1>
                    <p className={"text-center"}>
                      La mission que vous cherchez est introuvable.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className={"flex flex-1"}>
            <div
              className={
                "flex w-full h-fit p-4 border border-gray-100 rounded-lg sticky top-0 max-w-[450px]"
              }
            >
              <div className={"flex flex-col gap-8 w-full"}>
                <div className={"font-black"}>Informations</div>
                <div className={"flex justify-between items-center text-sm"}>
                  <div className={"font-black"}>Période</div>
                  <div className={"text-gray-500"}>
                    {missionDetail?.dates?.start
                      ? dateShortText(new Date(missionDetail?.dates?.start))
                      : null}{" "}
                    >{" "}
                    {missionDetail?.dates?.end
                      ? dateShortText(new Date(missionDetail?.dates?.end))
                      : null}
                  </div>
                </div>
                <div className={"flex justify-between items-center text-sm"}>
                  <Tooltip
                    placement={"top"}
                    text={
                      "L'intérimaire disposera d'un accès au code jusqu'à cette date."
                    }
                  >
                    <div
                      className={
                        "font-black flex gap-1 items-center underline decoration-dashed"
                      }
                    >
                      <div>Fin de souplesse estimée</div>
                      <div>
                        <InformationCircle wh={16} color={"#374151"} />
                      </div>
                    </div>
                  </Tooltip>
                  <div className={"text-gray-500"}>
                    {missionDetail?.flexibility?.end
                      ? dateShortText(new Date(missionDetail?.flexibility?.end))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Mission;
