import conditions from "./MissionFileImportConditions.json";
import { dateStringIsValid } from "./Date";

const targetColumns = [2, 3, 4, 5, 7, 8, 13, 16, 18, 28, 30];

const missionFileImportAuthenticity = (fileJSON) => {
  const errors = [];

  if (fileJSON[0].length !== conditions.length) {
    errors.push("Le nombre de colonnes ne correspond pas aux spécifications.");
  }

  for (let i = 0; i < fileJSON.length; i++) {
    const row = fileJSON[i];

    if (row.length === 0 || row.length === 1) continue;

    for (let j = 0; j < conditions.length; j++) {
      const columnSpec = conditions[j];
      const cellValue = row[j];

      if (!targetColumns.includes(columnSpec.id)) continue;

      if (
        !columnSpec.required &&
        (cellValue === undefined || cellValue.trim() === "")
      )
        continue;

      if (!columnSpec.type) continue;

      if (cellValue !== undefined && cellValue.length > columnSpec.length) {
        errors.push(
          `La valeur dans la colonne ${j + 1} de la ligne ${i + 1} dépasse la longueur maximale autorisée : ${columnSpec.name} : ${cellValue} => ${cellValue.length} > ${columnSpec.length}. `,
        );
      }

      if (columnSpec.type === "Date") {
        if (cellValue !== undefined) {
          const trimmedCellValue = cellValue.trim();
          if (trimmedCellValue === "") {
            errors.push(
              `La valeur dans la colonne ${j + 1} de la ligne ${i + 1} est requise pour le type de date.`,
            );
          } else if (!dateStringIsValid(trimmedCellValue)) {
            errors.push(
              `La valeur dans la colonne ${j + 1} de la ligne ${i + 1} n'est pas une date valide : ${trimmedCellValue}.`,
            );
          }
        } else {
          errors.push(
            `La valeur dans la colonne ${j + 1} de la ligne ${i + 1} est requise pour le type de date.`,
          );
        }
      } else if (columnSpec.type === "Numeric") {
        if (isNaN(cellValue) || cellValue.trim() === "") {
          errors.push(
            `La valeur dans la colonne ${j + 1} de la ligne ${i + 1} doit être un nombre.`,
          );
        }
      } else if (columnSpec.type === "AlphaNum") {
        if (!/^[a-zA-Z0-9]+$/.test(cellValue)) {
          errors.push(
            `La valeur dans la colonne ${j + 1} de la ligne ${i + 1} ne correspond pas au type alphanumérique.`,
          );
        }
      } else if (columnSpec.type === "Text") {
      } else {
        errors.push(
          `Type de données non pris en charge pour la colonne ${j + 1}: ${columnSpec.type}.`,
        );
      }

      if (
        columnSpec.required &&
        (cellValue === undefined || cellValue.trim() === "")
      ) {
        errors.push(
          `La valeur dans la colonne ${j + 1} de la ligne ${i + 1} est requise.`,
        );
      }
    }
  }

  return errors;
};

export default missionFileImportAuthenticity;
